import React from 'react';
import NavItem from './NavItem';

const Navigation = ({ selectedItem, handleClick }) => {
  return (
    <ul className="nav">
      <NavItem item="Home" selectedItem={selectedItem} handleClick={handleClick} />
      <NavItem item="Resume" selectedItem={selectedItem} handleClick={handleClick} />
      <NavItem item="Portfolio" selectedItem={selectedItem} handleClick={handleClick} />
      <NavItem item="ToyProject" selectedItem={selectedItem} handleClick={handleClick} />
    </ul>
  );
};

export default Navigation;

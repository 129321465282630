import React, { useState } from 'react';
import BusinessCard from './components/BusinessCard'; // 컴포넌트 불러오기
import Navigation from './components/Navigation';
import ResumeContent from './components/ResumeContent';
import PortfolioContent from './components/PortfolioContent';
import ToyProjectContent from './components/ToyProjectContent';
import './App.css';

function App() {
  const logo = process.env.PUBLIC_URL + '/logo.png'; // logo 경로 설정

  const [selectedItem, setSelectedItem] = useState('Home'); // useState 사용

  const handleClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div className="App">
      <div className="container" style={{ width: '90%', maxWidth: '800px' }}>
        <div className="row">
          <div className="col">
            <a href="/" style={{ textDecoration: 'none' }}>
              <div className="logoContainer">
                <img src={logo} alt="Logo" className="logo" />
                Dahyoung World
              </div>
            </a>
          </div>
        </div>
        <div className="row justify-content-center">
          {/* <BusinessCard /> */}
          <div className="col">
            <Navigation selectedItem={selectedItem} handleClick={handleClick} />
            {selectedItem === 'Home' && <BusinessCard />}
            {selectedItem === 'Resume' && <ResumeContent />}
            {selectedItem === 'Portfolio' && <PortfolioContent />}
            {selectedItem === 'ToyProject' && <ToyProjectContent />}
          </div>
        </div>

        <div className="footer">Dahyoung World</div>
      </div>
    </div>
  );
}

export default App;

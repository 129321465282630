import React from 'react';
import './PortfolioContent.css';

const PortfolioContent = () => {
  return (
    <div className="portfolio-container">
      <div className="portfolio-box">
        <h3>Unity Casual Game</h3>
        <div className="icon-container">
          <img
            src={
              'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbnJbPQ%2FbtsISIg2dtK%2FDJV2tjq9RWAqN9OQNaJljK%2Fimg.png'
            }
            className="icon"
          />

          <div className="content">
            <h4>SIMPLE BRAIN BOOSTERS</h4>
            <p>단순 두뇌 게임 모음</p>
            <div className="button-container">
              <a href="https://daink.github.io/unity-casual-game/" target="_blank">
                <button>Play WebGL</button>
              </a>

              <a href="https://github.com/dainK/unity-casual-game/tree/main/Script" target="_blank">
                <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FEp7Y1%2FbtsITfTQhVp%2Fjwsll4yMmwykSZAw5FBekk%2Fimg.png"></img>
              </a>

              {/* <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb3BB69%2FbtsIQxuuu4j%2FUID0KrGFzdTbEV5YBeynfk%2Fimg.png"></img> */}

              {/* <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FyFEoV%2FbtsIRmsqTTd%2FxOr3ioYSufMzOgAUagKU81%2Fimg.png"></img> */}
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="portfolio-box">
        <h3>Web Full-Stack Project</h3>
        <div className="icon-container">
          <img
            src={
              'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbcV24u%2FbtsIV6vucDE%2FwRnIEuPOENPiHFsLGa2ej0%2Fimg.png'
            }
            className="icon"
          />

          <div className="content">
            <h4>STUDY CAMP </h4>

            <div className="button-container">
              <p>화상통화, 화면공유를 할 수 있는</p>
              <span style={{ fontWeight: 'bold' }}> 메타버스 학습 공간 </span>
              <a href="https://www.studycamp.site/" target="_blank">
                <button>Visit Website</button>
              </a>
            </div>
            <br></br>

            <div className="button-container">
              <p> Front-end</p>

              <a href="https://github.com/dainK/study-camp-client" target="_blank">
                <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FEp7Y1%2FbtsITfTQhVp%2Fjwsll4yMmwykSZAw5FBekk%2Fimg.png"></img>
              </a>
            </div>

            <div className="tag">
              <img src="https://img.shields.io/badge/javascript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=white" />
              {/* <img src="https://img.shields.io/badge/TypeScript-3178C6?style=for-the-badge&logo=typescript&logoColor=white" /> */}

              {/* <img src="https://img.shields.io/badge/webrtc-333333?style=for-the-badge&logo=webrtc&logoColor=white" /> */}
              <img src="https://img.shields.io/badge/phaser-ccc?style=for-the-badge&logo=&logoColor=white" />
              <img src="https://img.shields.io/badge/vite-646CFF?style=for-the-badge&logo=vite&logoColor=white" />
              <img src="https://img.shields.io/badge/react-61dafb?style=for-the-badge&logo=react&logoColor=white" />
              <img src="https://img.shields.io/badge/socket.io-010101?style=for-the-badge&logo=socketdotio&logoColor=white" />
              <img src="https://img.shields.io/badge/webrtc-333333?style=for-the-badge&logo=webrtc&logoColor=white" />
              <img src="https://img.shields.io/badge/axios-5A29E4?style=for-the-badge&logo=axios&logoColor=white" />

              <img src="https://img.shields.io/badge/bootstrap-7952B3?style=for-the-badge&logo=bootstrap&logoColor=white" />
              <img src="https://img.shields.io/badge/css3-1572B6?style=for-the-badge&logo=css3&logoColor=white" />
              <img src="https://img.shields.io/badge/html5-E34F26?style=for-the-badge&logo=html5&logoColor=white" />
            </div>

            <br></br>
            <br></br>

            <div className="button-container">
              <p>Back-end</p>
              <a href="https://github.com/dainK/study-camp-server" target="_blank">
                <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FEp7Y1%2FbtsITfTQhVp%2Fjwsll4yMmwykSZAw5FBekk%2Fimg.png"></img>
              </a>
            </div>

            <div className="tag">
              <img src="https://img.shields.io/badge/TypeScript-3178C6?style=for-the-badge&logo=typescript&logoColor=white" />
              <img src="https://img.shields.io/badge/Node.js-339933?style=for-the-badge&logo=nodedotjs&logoColor=white" />
              <img src="https://img.shields.io/badge/NestJS-E0234E?style=for-the-badge&logo=nestjs&logoColor=white" />
              {/* <img src="https://img.shields.io/badge/express-000000?style=for-the-badge&logo=express&logoColor=white" /> */}
              {/* <img src="https://img.shields.io/badge/socket.io-010101?style=for-the-badge&logo=socketdotio&logoColor=white" /> */}
              <img src="https://img.shields.io/badge/typeorm-FE0803?style=for-the-badge&logo=typeorm&logoColor=white" />
              <img src="https://img.shields.io/badge/MySQL-4479A1?style=for-the-badge&logo=mysql&logoColor=white" />
              <img src="https://img.shields.io/badge/Redis-DC382D?style=for-the-badge&logo=redis&logoColor=white" />
              {/* <img src="https://img.shields.io/badge/AWS-232F3E?style=for-the-badge&logo=amazonaws&logoColor=white" />{' '} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioContent;

import React, { useState } from 'react';
import './ResumeContent.css'; // 스타일 파일을 따로 분리합니다.

const ResumeContent = () => {
  // 초기 섹션 상태 배열
  const initialSections = [
    { isVisible: false, header: '업무내용' },
    { isVisible: false, header: '업무내용' },
    { isVisible: false, header: '업무내용' },
  ];

  // 섹션 상태를 useState로 관리합니다.
  const [sections, setSections] = useState(initialSections);

  // 섹션의 isVisible 상태를 토글하는 함수
  const toggleVisibility = (index) => {
    const updatedSections = [...sections]; // 배열 복사
    updatedSections[index].isVisible = !updatedSections[index].isVisible; // isVisible 반전
    setSections(updatedSections); // 상태 업데이트
  };

  // 섹션을 렌더링하는 함수
  const renderSection = (index, content) => {
    return (
      <div key={index}>
        <h5
          className={`toggle-header ${sections[index].isVisible ? 'expanded' : 'collapsed'}`}
          onClick={() => toggleVisibility(index)}
        >
          {sections[index].header}
          <button className="toggle-button">{sections[index].isVisible ? '▲' : '▼'}</button>
        </h5>

        <div className={`career_info ${sections[index].isVisible ? 'visible' : 'hidden'}`}>
          {/* 각 섹션에 대한 내용을 여기에 넣으세요 */}
          {content}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="resume-container">
        {/* <hr></hr> */}
        <h2>학력</h2>
        <div className="education-grid-container">
          <div className="resume-label">2012.03 ~ 2016.02</div>
          <div className="resume-label">호서대학교</div>
          <div className="career-text">
            게임공학과 전공 <br></br>정보보호학과 부전공
          </div>
        </div>
        {/* <hr></hr> */}
        <h2>자격증</h2>
        <div className="resume-grid-container">
          <div className="resume-label">2023.12.15</div>
          <div className="resume-label">SQL 개발자 (한국데이터산업진흥원)</div>
        </div>
        {/* <hr></hr> */}
        <h2>보유 기술</h2>
        <div className="resume-grid-container">
          <div className="resume-label">Language</div>
          <div className="resume-text">
            C <span style={{ color: '#ccc' }}>| </span>
            C++<span style={{ color: '#ccc' }}>| </span>
            C# <span style={{ color: '#ccc' }}>| </span>
            JavaScript <span style={{ color: '#ccc' }}>| </span>
            TypeScript
          </div>

          <div className="resume-label">GameEngine </div>
          <div className="resume-text">
            Unity3D <span style={{ color: '#ccc' }}>| </span>
            Cocos2d-x <span style={{ color: '#ccc' }}>| </span>
            Cocos Creator <span style={{ color: '#ccc' }}>| </span>
            Phaser
          </div>

          <div className="resume-label">DataBase</div>
          <div className="resume-text">
            MySQL <span style={{ color: '#ccc' }}>| </span>
            MariaDB <span style={{ color: '#ccc' }}>| </span>
            Redis <span style={{ color: '#ccc' }}>| </span>
            MongoDB <span style={{ color: '#ccc' }}>| </span>
            FireBase
          </div>

          <div className="resume-label">Back-end</div>
          <div className="resume-text">
            Node.js <span style={{ color: '#ccc' }}>| </span>
            Nest <span style={{ color: '#ccc' }}>| </span>
            Express <span style={{ color: '#ccc' }}>| </span>
            Socket.io
          </div>

          <div className="resume-label">Front-end</div>
          <div className="resume-text">
            CSS3 <span style={{ color: '#ccc' }}>| </span>
            HTML5 <span style={{ color: '#ccc' }}>| </span>
            Bootstrap <span style={{ color: '#ccc' }}>| </span>
            React <span style={{ color: '#ccc' }}>| </span>
            Vite
          </div>

          <div className="resume-label">DevOps</div>
          <div className="resume-text">
            AWS EC2 <span style={{ color: '#ccc' }}>| </span>
            AWS S3 <span style={{ color: '#ccc' }}>| </span>
            AWS Route53 <span style={{ color: '#ccc' }}>| </span>
            AWS RDS
          </div>
        </div>
        {/* <hr></hr> */}
        <h2>경력 사항</h2>
        {/* 캔디코드 */}
        <div className="career-box-container">
          {/* <div className="career-label"> */}
          <p>
            <span style={{ fontWeight: 'bold', fontSize: '24px' }}>캔디코드 </span>
            <span style={{ fontSize: '16px' }}>2022.06 ~ 2023.02 </span>
          </p>
          <div className="career-grid-container">
            <div className="career-label">개발 환경</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/rider-000000?style=for-the-badge&logo=rider&logoColor=white" />
            </div>
            <div className="career-label">사용 엔진</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/unity-000000?style=for-the-badge&logo=unity&logoColor=white" />
            </div>
            <div className="career-label">사용 언어</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/C%23-000000?style=for-the-badge&logo=csharp&logoColor=white" />
            </div>
            {/* </div> */}
          </div>
          {/* <br></br> */}
          <div className="career-text">
            {/* <h4>업무내용</h4> */}

            {renderSection(
              0,
              <>
                <div className="career-header">
                  <h5>플렛폼 컨텐츠 개발</h5>
                </div>
                <div className="career-container">
                  <h6>- 아바타 꾸미기 컨텐츠</h6>
                  <span>
                    3D 모델의 변경해야 할 뼈대만 바꿔 적용하여 스킨 교체 기능을 다이나믹 본 작업을
                    하여 구현 하였습니다.
                  </span>
                  <hr></hr>
                  <h6>- 마이룸 타일 맵 구현</h6>
                  <span>
                    구입한 방들을 3D 타일맵을 이용하여 보여 주고 입장 할 수 있도록 제작 하였습니다.
                  </span>
                  <hr></hr>
                  <h6>- 3D 슬롯 개발</h6>
                  <span>초기 설계부터 비동기 통신까지 전체적인 기본 플로우를 구현 하였습니다</span>
                </div>

                <div className="career-header">
                  <h5>VR 컨텐츠 개발</h5>
                </div>
                <div className="career-container">
                  <h6>- Unity, Oculus를 이용한 VR 교육 컨텐츠 외주 작업</h6>
                  <span>
                    글로브포인트의 외부 기획서의 내용을 반영하고 피드백 대응을 하며 개발 하였습니다.
                  </span>
                </div>
              </>,
            )}
            {/* {renderSection(
              1,
              <>
                <div className="career-container">
                  <h6>- Unity, Oculus를 이용한 VR 교육 컨텐츠 외주 작업</h6>
                  <span>
                    글로브포인트의 외부 기획서의 내용을 반영하고 피드백 대응을 하며 개발 하였습니다.
                  </span>
                </div>
              </>,
            )} */}
          </div>
        </div>
        {/* 플라이셔 */}
        <hr></hr>
        <div className="career-box-container">
          {/* <div className="career-label"> */}
          <p>
            <span style={{ fontWeight: 'bold', fontSize: '24px' }}>플라이셔(플레이링스) </span>
            <span style={{ fontSize: '16px' }}>2018.09 ~ 2022.06 </span>
          </p>
          <div className="career-grid-container">
            <div className="career-label">개발 환경</div>

            <div className="tag">
              <img src="https://img.shields.io/badge/webstorm-000000?style=for-the-badge&logo=webstorm&logoColor=white" />
            </div>
            <div className="career-label">사용 엔진</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/cocos2dx-000000?style=for-the-badge&logo=cocos&logoColor=white" />
            </div>
            <div className="career-label">사용 언어</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/javascript-000000?style=for-the-badge&logo=javascript&logoColor=white" />
            </div>
            <div className="career-label">프로젝트</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/Rock’N Cash-000000?style=for-the-badge&logo=&logoColor=white" />
            </div>
            {/* </div> */}
          </div>
          <div className="career-text">
            {renderSection(
              1,
              <>
                <div className="career-header">
                  <h5>신규 컨텐츠 개발 및 배포</h5>
                </div>
                <div className="career-container">
                  <h6>- 페이스북 슬롯 게임 Rock’N Cash의 신규 슬롯 제작 및 배포</h6>
                  <span>신규 슬롯 제작과 모바일 포팅 작업, 배포를 하였습니다.</span>
                  <hr></hr>
                  <h6>- 키노 설계 및 제작</h6>
                  <span>kino 컨텐츠 설계 및 개발 및 배포를 하였습니다.</span>
                </div>
                <div className="career-header">
                  <h5>모듈화 작업</h5>
                </div>
                <div className="career-container">
                  <h6>- Pot기능, Pick기능 모듈화 작업</h6>
                  <span>
                    자주 구현하게 되는 pot(보너스 피쳐 확률 단계를 보여주는 기능)과 pick(보너스 피쳐
                    뽑기 기능)을 팀원들이 사용하기 쉽게 구현 하였습니다.
                  </span>
                </div>
              </>,
            )}
          </div>
        </div>
        {/* 카카오브이엑스 */}
        <hr></hr>
        <div className="career-box-container">
          {/* <div className="career-label"> */}
          <p>
            <span style={{ fontWeight: 'bold', fontSize: '24px' }}>kakaoVX </span>
            <span style={{ fontSize: '16px' }}>2015.07 ~ 2018.08 </span>
          </p>
          <div className="career-grid-container">
            <div className="career-label">개발 환경</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/visual_studio-000000?style=for-the-badge&logo=visualstudio&logoColor=white" />
            </div>
            <div className="career-label">사용 엔진</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/directX-000000?style=for-the-badge&logo=&logoColor=white" />
            </div>
            <div className="career-label">사용 언어</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/c++-000000?style=for-the-badge&logo=cplusplus&logoColor=white" />
            </div>
            <div className="career-label">프로젝트</div>
            <div className="tag">
              <img src="https://img.shields.io/badge/t1-000000?style=for-the-badge&logo=&logoColor=white" />
            </div>
            {/* </div> */}
          </div>
          <div className="career-text">
            {renderSection(
              2,
              <>
                <div className="career-header">
                  <h5>런처 업데이트 및 배포</h5>
                </div>
                <div className="career-container">
                  <div className="career-box">
                    <span>
                      자동화된 배치파일로 패치 리소스를 만들어 시스템 관리자에게 전달하는 일을
                      하였습니다.
                    </span>
                    <span>
                      패치파일의 기준시간이 한국시간으로 기록하여 해외패치에 문제가 있어
                      세계표준시간(UTC)으로 패치파일의 기준시간을 수정하여 문제를 해결했습니다.
                    </span>
                  </div>
                </div>
                <div className="career-header">
                  <h5>CS 대응 툴 개발</h5>
                </div>
                <div className="career-container">
                  <span>
                    카메라 센서로 찍은 연속 이미지를 영상으로 재생 시켜주는 MFC툴을 제작하여 관련
                    문의가 개발자로 오는 횟수를 줄였습니다.
                  </span>
                  <br />
                </div>
                <div className="career-header">
                  <h5>TTS 라이브러리 적용</h5>
                </div>
                <div className="career-container">
                  <div className="career-box">
                    <span>
                      새로운 TTS엔진을 사용하게 되어 보이스웨어에서 제공 받은 라이브러리와 메뉴얼을
                      통해 프로젝트에서 TTS를 사용 할 수 있도록 적용하였습니다.
                    </span>
                    <span>
                      신규 매장에서 자동으로 보이스웨어의 라이센스를 받아올 수 있도록
                      작업하였습니다.
                    </span>
                    <span>
                      보이스웨어의 서버 문제로 라이센스 갱신이 되지 않는 경우 보이스웨어와 직접
                      연락을 통해 문제를 해결하였습니다.
                    </span>
                  </div>
                </div>

                <div className="career-header">
                  <h5>편의 기능 개발</h5>
                </div>
                <div className="career-container">
                  <h6>- 매장주 편의 기능 개발</h6>
                  <div className="career-box">
                    <span>
                      각 클럽마다 다른 비거리 보정과 방향 보정 등을 설정할 수 있도록 구현하였습니다.
                    </span>
                    <span>
                      연습장 모드 이용시간이 끝나면 자동으로 연습장을 빠져나오도록 작업하였습니다.
                    </span>
                  </div>
                  <hr></hr>
                  <h6>- 유저 편의 기능 개발</h6>
                  <div className="career-box">
                    <span>
                      공에서 시선 방향으로 ray를 쏘아 언덕 등 장애물을 넘길 수 있는 각도를 보여주는
                      작업을 하였습니다.
                    </span>
                    <span>티 박스 안에서 티를 좌우로 이동 할 수 있는 기능을 추가하였습니다.</span>

                    <span>
                      특정 유저에게 다른 유저들이 홀 아웃을 하기 전까지 턴이 오지 않도록 설정 할 수
                      있는 기능 구현하였습니다. (식사 모드)
                    </span>
                    <span>
                      홀 아웃이 되기 전까지 턴을 넘기지 않고 퍼팅을 이어 할 수 있는 기능을
                      구현했습니다.
                    </span>
                    <span>퍼팅 시 경사를 알려주는 멘트를 다시 들을 수 있도록 구현하였습니다.</span>

                    <span>
                      공이 해저드에 들어간 시점 좌표에서 공을 칠 수 있도록 구현하였습니다.
                    </span>
                  </div>
                </div>
              </>,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeContent;

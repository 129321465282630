import React from 'react';
import './ToyProjectContent.css'; // 스타일 파일을 따로 분리합니다.

const ToyProjectContent = () => {
  return (
    <div className="toyProject-container">
      <div className="card-box">
        {/* ProjectLM */}
        {/* <div className="card-container">
          <div className="card">
            <div className="image-container">
              <img className="card-img-top" src="../images/unity.png" alt="card" />
              <div className="image-button-container">
                <a href="" title="" target="_blank" className="btn disabled">
                  Go to
                </a>
                <a href="/projectLM" className="btn">
                  Details
                </a>
              </div>
            </div>

            <div className="card-body">
              <h3>PROJECT LM</h3>
              <p>1인 개발 인디게임 프로젝트</p>

              <div className="tag">
                <img src="https://img.shields.io/badge/C%23-512BD4?style=for-the-badge&logo=csharp&logoColor=white" />
                <img src="https://img.shields.io/badge/unity-000000?style=for-the-badge&logo=unity&logoColor=white" />
              </div>
            </div>
          </div>
        </div> */}

        {/* 2048 */}
        <div className="card-container">
          <div className="card">
            <div className="image-container">
              <a href="https://daink.github.io/2048/" title="2048 game 페이지 열기" target="_blank">
                <img
                  className="card-img-top"
                  src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FqC7JN%2FbtsIhhFEkxN%2FIFW1pNRzIcIpiUvYstfRE0%2Fimg.png"
                  alt="card"
                />
              </a>
            </div>
            <div className="card-body">
              <h3>2048 GAME</h3>
              <p>2048</p>

              <div className="tag">
                <img src="https://img.shields.io/badge/unity-000000?style=for-the-badge&logo=unity&logoColor=white" />
                <img src="https://img.shields.io/badge/C%23-512BD4?style=for-the-badge&logo=csharp&logoColor=white" />
                <img src="https://img.shields.io/badge/github_pages-222222?style=for-the-badge&logo=githubpages&logoColor=white" />
              </div>
            </div>
          </div>
        </div>

        {/* suika */}
        <div className="card-container">
          <div className="card">
            <div className="image-container">
              <a
                href="https://daink.github.io/standard_social_game_client/"
                title="SUIKA GAME 페이지 열기"
                target="_blank"
              >
                <img
                  className="card-img-top"
                  src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FHy38o%2FbtsIhVaYit2%2F5cgDC8I3ZgrtjrSUoFEq3k%2Fimg.png"
                  alt="card"
                />
              </a>
            </div>
            <div className="card-body">
              <h3>SUIKA GAME</h3>
              <p>랭킹 서버 내렸는데 수정 아직 안했음</p>

              <div className="tag">
                <img src="https://img.shields.io/badge/vite-646CFF?style=for-the-badge&logo=vite&logoColor=white" />
                <img src="https://img.shields.io/badge/matter.js-4B5562?style=for-the-badge&logo=matterdotjs&logoColor=white" />
                <img src="https://img.shields.io/badge/javascript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=white" />
                <img src="https://img.shields.io/badge/github_actions-2088FF?style=for-the-badge&logo=githubactions&logoColor=white" />
                <img src="https://img.shields.io/badge/github_pages-222222?style=for-the-badge&logo=githubpages&logoColor=white" />
              </div>
            </div>
          </div>
        </div>

        {/* 3match */}
        <div className="card-container">
          <div className="card">
            <div className="image-container">
              <a
                href="https://daink.github.io/cocos_creator_3match/"
                title="3MATCH GAME 페이지 열기"
                target="_blank"
              >
                <img
                  className="card-img-top"
                  src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FrKzO4%2FbtsIiUbjHkT%2Fzq3cfScldWaKoW0rpO8Cf1%2Fimg.png"
                  alt="card"
                />
              </a>
            </div>
            <div className="card-body">
              <h3>3MATCH GAME</h3>
              <p>코코스 크리에이트 테스트</p>

              <div className="tag">
                <img src="https://img.shields.io/badge/cocos_creator-55C2E1?style=for-the-badge&logo=cocos&logoColor=white" />
                <img src="https://img.shields.io/badge/TypeScript-3178C6?style=for-the-badge&logo=typescript&logoColor=white" />
                <img src="https://img.shields.io/badge/github_pages-222222?style=for-the-badge&logo=githubpages&logoColor=white" />
              </div>
            </div>
          </div>
        </div>

        <div className="card-container">
          <div className="card">
            <div className="image-container">
              <a
                href="https://daink.github.io/cocos_creator_tutorial/"
                title="cocos creator game 페이지 열기"
                target="_blank"
              >
                <img
                  className="card-img-top"
                  src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F4ihah%2FbtsIipo9Fzt%2FgNwUk4OUqTe9ndIoQsjT60%2Fimg.png"
                  alt="card"
                />
              </a>
            </div>
            <div className="card-body">
              <h3>CASUAL GAME</h3>
              <p>코코스 튜토리얼</p>

              <div className="tag">
                <img src="https://img.shields.io/badge/cocos_creator-55C2E1?style=for-the-badge&logo=cocos&logoColor=white" />
                <img src="https://img.shields.io/badge/javascript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=white" />
                <img src="https://img.shields.io/badge/github_pages-222222?style=for-the-badge&logo=githubpages&logoColor=white" />
              </div>
            </div>
          </div>
        </div>

        <div className="card-container">
          <div className="card">
            <div className="image-container">
              <a
                href="https://daink.github.io/webGL_Runner/"
                title="runner game 페이지 열기"
                target="_blank"
              >
                <img
                  className="card-img-top"
                  src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fw5V3U%2FbtsIgPv8Txw%2FMAugRho4lHuSPK1KCGFbX0%2Fimg.png"
                  alt="card"
                />
              </a>
            </div>
            <div className="card-body">
              <h3>RUNNER GAME</h3>
              <p>러너 게임 빌드테스트</p>

              <div className="tag">
                <img src="https://img.shields.io/badge/unity-000000?style=for-the-badge&logo=unity&logoColor=white" />
                <img src="https://img.shields.io/badge/C%23-512BD4?style=for-the-badge&logo=csharp&logoColor=white" />
                <img src="https://img.shields.io/badge/github_pages-222222?style=for-the-badge&logo=githubpages&logoColor=white" />
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="card-container">
          <div className="card">
            <div className="image-container">
              <a
                href="https://daink.github.io/webGL_Adventure/"
                title="ADVENTURE GAME 페이지 열기"
                target="_blank"
              >
                <img
                  className="card-img-top"
                  src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc0Vowq%2FbtsIjhKU0X2%2FHgw19fKeAEhnBoadoyrX8K%2Fimg.png"
                  alt="card"
                />
              </a>
            </div>
            <div className="card-body">
              <h3>ADVENTURE</h3>
              <p>...</p>

              <div className="tag">
                <img src="https://img.shields.io/badge/C%23-512BD4?style=for-the-badge&logo=csharp&logoColor=white" />
                <img src="https://img.shields.io/badge/unity-000000?style=for-the-badge&logo=unity&logoColor=white" />
                <img src="https://img.shields.io/badge/webgl-990000?style=for-the-badge&logo=webgl&logoColor=white" />
                <img src="https://img.shields.io/badge/github_pages-222222?style=for-the-badge&logo=githubpages&logoColor=white" />{' '}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ToyProjectContent;

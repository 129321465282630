import React from 'react';
import './BusinessCard.css'; // 스타일 파일을 따로 분리합니다.

function BusinessCard() {
  const profileImageUrl =
    'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdC5Zbb%2FbtsIgYM1Zol%2FOlG2QMWwh9xFJTHUVHn6EK%2Fimg.png'; // 이미지 URL 경로

  return (
    <div className="content-container">
      <p className="content-about">안녕하세요. 성장을 멈추지 않는 개발자입니다.</p>
      <div className="business-card">
        <div className="image-container">
          <img src={profileImageUrl} alt="Profile" className="profile-image" />
        </div>
        <div className="info-container">
          <h2>Kang Dahyoung</h2>

          <div className="grid-container">
            <div className="info-label">Date of Birth :</div>
            <div className="info-text">1994.02.09</div>

            <div className="info-label">Email :</div>
            <div className="info-text">dain940209@gmail.com</div>

            <div className="info-label">Github :</div>
            <div className="info-text">
              {' '}
              <a
                class="mb-0"
                href="https://github.com/daink"
                target="_blank"
                title="github 페이지 열기"
              >
                https://github.com/daink
              </a>
            </div>

            <div className="info-label">Blog :</div>
            <div className="info-text">
              {' '}
              <a
                class="mb-0"
                href="https://dadah.tistory.com"
                target="_blank"
                title="tistory 페이지 열기"
              >
                https://dadah.tistory.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCard;
